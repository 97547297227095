import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MenuStickyComponent } from '@shared/components/v1/menu-sticky/menu-sticky.component'
import { Router } from '@angular/router'

@Component({
  selector: 'app-gt-menu-sticky',
  standalone: true,
  imports: [CommonModule, MenuStickyComponent],
  templateUrl: './gt-menu-sticky.component.html',
})
export class GtMenuStickyComponent {
  constructor(private router: Router) {}

  @Input()
  activeMenuName: string = 'gt'
  @Output()
  activeMenuNameChange = new EventEmitter<string>()

  gtStickyMenuItems = [
    {
      imgSrc: 'assets/icons/shopping-bag.svg',
      activeImgSrc: 'assets/icons/shopping-bag-act.svg',
      label: 'ซื้อสินค้า',
      value: 'homepage',
    },
    {
      imgSrc: 'assets/icons/invoice.svg',
      activeImgSrc: 'assets/icons/invoice-act.svg',
      label: 'ประวัติสั่งซื้อ',
      value: 'order-history',
    },
    {
      imgSrc: 'assets/icons/discount.svg',
      activeImgSrc: 'assets/icons/discount-act.svg',
      label: 'ส่วนลด',
      value: 'user-coupon',
    },
    {
      imgSrc: 'assets/icons/shop-setting.svg',
      activeImgSrc: 'assets/icons/shop-setting-act.svg',
      label: 'จัดการร้านค้า',
      value: 'admin-menu',
    },
  ]

  navigateTo(value: string) {
    let navPath: string[] = []
    if (value === 'homepage') {
      navPath = ['gt']
    } else if (value === 'order-history') {
      navPath = ['gt', 'order', 'history']
    } else if (value === 'admin-menu') {
      navPath = ['gt', 'admin-menu']
    } else if (value === 'user-coupon') {
      navPath = ['gt', 'user-coupon']
    } else {
      navPath = ['gt']
    }

    this.router.navigate(navPath).then(() => {
      this.activeMenuNameChange.emit(value)
    })
  }
}
