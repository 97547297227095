<div class="flex flex-col h-screen">
  <div class="flex-1 overflow-y-auto main-scroll">
    <router-outlet></router-outlet>
    <ng-container *ngIf="hasUpdate$ | async">
      @if (showUpdateModal()) {
        <app-modal-product-update-from-master />
      }
    </ng-container>
  </div>
  <div>
    <app-ws-menu-sticky [value]="activeMenuService.currentActiveMenu()"></app-ws-menu-sticky>
    <app-warning-banner />
  </div>
</div>
