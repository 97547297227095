<div
  class="grid grid-cols-3 text-center bg-white py-1.5 shadow-[2px_-2px_4px_0px_rgba(0,0,0,0.10)]"
  [class]="gridColumn"
>
  <button
    *ngFor="let item of menuItem"
    [ngClass]="{ active: item.value === value }"
    class="group cursor-pointer"
    (click)="click(item.value)"
  >
    <div class="relative inline-block">
      <div
        *ngIf="item.numOfOrder"
        class="absolute -top-[3px] -right-1.5 w-4 h-4 bg-error rounded-full text-white text-[10px] flex items-center justify-center"
      >
        {{ item.numOfOrder }}
      </div>
      <img [src]="item.value === value ? item.activeImgSrc : item.imgSrc" [alt]="item.label" class="mx-auto" />
    </div>
    <span class="block text-xs mt-0.5 group-[.active]:text-primary-blue">{{ item.label }}</span>
  </button>
</div>
