import { Component, inject } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { WarningBannerComponent } from '@shared/components/v1/warning-banner/warning-banner.component'
import { ActiveMenuService } from '@service/active-menu.service'
import { GtMenuStickyComponent } from '@shared/components/v1/gt-menu-sticky/gt-menu-sticky.component'
@Component({
  selector: 'app-gt-outlet',
  standalone: true,
  imports: [RouterOutlet, WarningBannerComponent, GtMenuStickyComponent],
  templateUrl: './gt-outlet.component.html',
})
export class GtOutletComponent {
  readonly activeMenuService = inject(ActiveMenuService)
}
