import { Component, inject } from '@angular/core'
import { WarningBannerService } from '@service/warning-banner.service'

@Component({
  selector: 'app-warning-banner',
  standalone: true,
  imports: [],
  templateUrl: './warning-banner.component.html',
  styleUrl: './warning-banner.component.css',
})
export class WarningBannerComponent {
  warningBannerService = inject(WarningBannerService)
  showWarningBanner = this.warningBannerService.showWarningBanner
}
