import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-menu-sticky',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-sticky.component.html',
})
export class MenuStickyComponent {
  @Input() value?: string
  @Input() menuItem: { imgSrc: string; activeImgSrc: string; label: string; value: string; numOfOrder?: number }[] = []
  @Output() buttonClick = new EventEmitter<string>()

  click(item: string) {
    this.value = item
    this.buttonClick.emit(item)
  }

  get gridColumn() {
    return `grid-cols-${this.menuItem.length}`
  }
}
