<app-modal
  [visible]="!cookieConsentService.shownCookieConsentModal()"
  [showHeader]="false"
  [contentRef]="content"
  [showFooter]="false"
>
  <ng-template #content>
    <div class="flex flex-col items-center space-y-2">
      <div class="shrink">
        <div class="rounded-full bg-[#E2F5FF] p-4 w-fit">
          <img src="assets/images/cookies.svg" alt="cookie" />
        </div>
      </div>
      <p class="font-bold text-xl">รวมมิตร มีการใช้งานคุกกี้</p>
      <p class="text-center">
        เพื่อเพิ่มประสิทธิภาพ และประสบการณ์ที่ดี ในการใช้งานเว็บไซต์ คุณสามารถเลือกตั้งค่าความยินยอม การใช้คุกกี้ได้
        โดยคลิก <button (click)="goToCookieSetting()" class="text-primary-blue underline">ตั้งค่าคุกกี้</button>
      </p>
      <div class="flex-1 w-full">
        <app-button
          (clickButton)="cookieConsentService.acceptAllCookies()"
          label="ยอมรับทั้งหมด"
          buttonSize="l-full"
          class="mt-4"
        ></app-button>
      </div>
    </div>
  </ng-template>
</app-modal>
